<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Background Image Share
        <div class="card-header-actions">
          <a class="card-header-action" href="background-share" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <div>
                <p><strong>Image Origin</strong></p>
                <b-img :src="this.form.image_path" fluid alt="Responsive image" style="max-width: 100%; height: auto" width="200px" height="200px"></b-img>
              </div>
              <br>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="image"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model="form.image"
                >
                </b-form-file>
                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <div>
                <p><strong>Icon Origin</strong></p>
                <b-img :src="this.form.icon_path" fluid alt="Responsive image" style="max-width: 100%; height: auto" width="200px" height="200px"></b-img>
              </div>
              <br>
              <b-form-group id="icon"
                            label="Icon"
                            label-for="icon">
                <b-form-file id="icon"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="iconFeedback"
                              v-model="form.icon"
                >
                </b-form-file>
                <b-form-invalid-feedback id="iconFeedback" v-for="(error , index) in errors.form.icon" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Status"
                  label-for="basicInlineCheckboxess">
                <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="status">
                  <b-form-checkbox :plain="true" value="true">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  name: "edit-backgrounds",
  data() {
    return {
      form: {
        image: '',
        image_path: '',
        icon: '',
        icon_path: ''
      },
      status: '',
      origin: '',
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          image: [],
        },
      },
      isLoading: false
    }
  },
  created(){

    this.$http.get(`saver/background/` + this.$route.params.id)
    .then((result) => {
      this.form.image_path = result.data.data.image_path;
      this.form.icon_path = result.data.data.icon_path;
      this.status = result.data.data.status;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    onSubmit() {
      this.isLoading = true
          const formData =  new FormData();
          if (this.form.image !== '') {
            formData.append('image', this.form.image)
          }
          if (this.form.icon !== '') {
            formData.append('icon', this.form.icon)
          }
          formData.append('_method', 'PUT')
          formData.append('status', this.status)
          this.$http.post(`saver/background/` + this.$route.params.id, formData)
          .then((result) => {
            this.isLoading = false
            this.$router.push("/background-share");
            this.$toasted.success(result.data.meta.message)
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 400) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.image = this.errors.message.image;
                this.errors.form.category = this.errors.message.category;
                this.errors.form.related = this.errors.message.type_id;
              } else if (this.errors.status == 422) {
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message.image[0],
                  'error'
                )
              }
            }
          })
    },
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
